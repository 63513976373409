import { AbstractView } from 'src/app/core/abstract_view';
import { Component, Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable()
@Component({
    selector: 'app-report-menu',
    templateUrl: './report-menu.component.html',
})
export class ReportMenuComponent extends AbstractView implements OnInit {
    public model: any[] = [];

    constructor(public messageService: MessageService, public router: Router) {
        super(messageService, router);
    }

    ngOnInit(): void {
        if (this.getAuth(['MANAGE_REPORT_PDV'])) {
            this.model = [
                {
                    label: 'Reportes',
                    icon: '',
                    routerLink: [`/reports/megapos`],
                },
            ];
            return;
        }

        if (this.getAuth(['MANAGE_REPORT_TREASURER'])) {
            this.model = [
                {
                    label: 'Reportes',
                    icon: '',
                    routerLink: [`/reports/treasurer`],
                },
            ];
            return;
        }

        if (this.getAuth(['MANAGE_REPORT_ACCOUNT'])) {
            this.model = [
                {
                    label: 'Reportes',
                    icon: '',
                    routerLink: [`/reports/account`],
                },
            ];
            return;
        }
        if (this.getAuth(['MANAGE_REPORT_COMMISSION'])) {
            this.model = [
                {
                    label: 'Reportes',
                    icon: '',
                    routerLink: [`/reports/commission`],
                },
            ];
            return;
        }
        if (this.getAuth(['MANAGE_REPORT_CLOSE_CASH'])) {
            this.model = [
                {
                    label: 'Reportes',
                    icon: '',
                    routerLink: [`/reports/treasurer`],
                },
            ];
            return;
        }
    }
}
