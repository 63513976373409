import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AbstractView } from 'src/app/core/abstract_view';
import { AppMainComponent } from '../main/app.main.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
})
export class AppSideBarComponent extends AbstractView implements OnInit {
    optionsEstablishments = false;
    optionsPrices = false;
    optionsUsers = false;
    optionsSuggested = false;
    optionsQuotations = false;
    optionsConfiguration = false;
    optionsReportsMp = false;
    optionsProduct = false;
    optionsCompensations = false;
    optionsPricingPdv = false;

    constructor(
        public app: AppMainComponent,
        private messageService: MessageService,
        public router: Router
    ) {
        super(messageService, router);
    }

    ngOnInit(): void {
        if (
            this.getAuth(['MANAGE_SUGGESTED']) ||
            this.getAuth(['PARAMETERS']) ||
            this.getAuth(['SETTINGS']) ||
            this.getAuth(['MANAGE_ASSIGMENT']) ||
            this.getAuth(['MANAGE_NEW_PRODUCTS']) ||
            this.getAuth(['MANAGE_RESTRICTIONS']) ||
            this.getAuth(['MANAGE_REPORTS'])
        ) {
            this.optionsSuggested = true;
        }
        if (this.getAuth(['MANAGE_USERS'])) {
            this.optionsUsers = true;
        }
        if (this.getAuth(['MANAGE_ESTABLISHMENTS'])) {
            this.optionsEstablishments = true;
        }
        if (
            this.getAuth(['MANAGE_PRICES']) ||
            this.getAuth(['MANAGE_PRICES_DEALER'])
        ) {
            this.optionsPrices = true;
        }
        if (this.getAuth(['MANAGE_QUOTES'])) {
            this.optionsQuotations = true;
        }
        if (this.getAuth(['MANAGE_BANKS']) || this.getAuth(['MANAGE_CLOSE_CASH'])) {
            this.optionsConfiguration = true;
        }
        if (
            this.getAuth(['MANAGE_REPORT_PDV']) ||
            this.getAuth(['MANAGE_REPORT_TREASURER']) ||
            this.getAuth(['MANAGE_REPORT_ACCOUNT']) ||
            this.getAuth(['MANAGE_REPORT_COMMISSION']) ||
            this.getAuth(['MANAGE_REPORT_CLOSE_CASH'])
        ) {
            this.optionsReportsMp = true;
        }
        this.optionsProduct = false;
        if (this.getAuth(['MANAGE_ESTABLISHMENTS'])) {
            this.optionsCompensations = true;
        }
        if (this.getAuth(['MANAGE_PRICES_PDV'])) {
        this.optionsPricingPdv = true;
        }
    }

}
